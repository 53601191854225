import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MobileNavigationComponent } from "./mobile/navigation.component";
import { DesktopNavigationComponent } from "./desktop/navigation.component";

@Component({
  selector: "talentsurf-app-header",
  template: `
    <header
      class="w-full top-0 left-0 z-50 sticky bg-white dark:bg-gray-800 shadow"
    >
      <talentsurf-app-desktop-navigation />
      <talentsurf-app-mobile-navigation />
    </header>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MobileNavigationComponent, DesktopNavigationComponent],
})
export class HeaderComponent {}
