import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./components/footer.component";
import { HeaderComponent } from "./components/header.component";
import { CompanyOverviewPageComponent } from "./pages/company-overview-page.component";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    CompanyOverviewPageComponent,
    IonicModule,
  ],
  selector: "talentsurf-app-root",
  template: `
    <div class="sm:block hidden">
      <talentsurf-app-header />
      <main class="min-h-screen">
        <router-outlet></router-outlet>
      </main>
      <talentsurf-app-footer />
    </div>

    <ion-menu class="sm:hidden block" contentId="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu Content</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">This is the menu content.</ion-content>
    </ion-menu>
    <div class="ion-page" id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        Tap the button in the toolbar to open the menu.
      </ion-content>
    </div>
  `,
})
export class AppComponent {}
