import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { from, map } from "rxjs";
import { appRoutes } from "./app.routes";
import { AVAILABLE_LANGUAGES } from "./models/model";
import { IonicModule } from "@ionic/angular";

class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`./i18n/${lang}.ts`)).pipe(map((obj) => obj.default));
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(
      IonicModule.forRoot({
        mode: "ios",
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: WebpackTranslateLoader,
        },
        defaultLanguage: AVAILABLE_LANGUAGES.de,
      }),
    ),
  ],
};
