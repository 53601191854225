import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "talentsurf-app-company-card",
  template: `
    <a [routerLink]="['/companies', company().id]">
      <div
        class="group relative aspect-[5/6] rounded-xl bg-gray-100 overflow-hidden text-center"
      >
        <img
          class="h-1/3 w-full object-cover group-hover:scale-105 transition duration-300"
          [src]="company().headerImageUrl"
          [alt]="company().name"
        />
        <img
          class="absolute left-0 right-0 top-1/3 -mt-12 mx-auto rounded-full w-16 sm:w-24 h-16 sm:h-24 shadow-xl group-hover:scale-105 transition duration-300 object-cover bg-gray-100"
          [src]="company().logo"
          [alt]="company().name + ' | Logo'"
        />
        <div class="px-2 md:px-4 py-3 mt-3 sm:mt-12 h-full items-center">
          <h2
            class="text-medium sm:text-xl font-bold group-hover:underline transition duration-500"
          >
            {{ company().name }}
          </h2>
          <div class="text-xs sm:text-sm grid gap-1 sm:gap-2 mt-2 sm:mt-3">
            @for (meta of company().metaData; track $index) {
              <div class="flex">
                <div
                  class="w-4 sm:w-5 font-bold text-primary-700 flex-none mr-1 md:mr-3"
                >
                  <img [src]="meta.iconUrl" [alt]="meta.text" />
                </div>
                <span class="flex-1">{{ meta.text }}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </a>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
  providers: [],
})
export class CompanyCardComponent {
  company = input<any>();
}
