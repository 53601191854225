import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "talentsurf-app-company-detail-page",
  template: ` <h1>Hello CompanyDetailPageComponent!</h1>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  providers: [],
})
export class CompanyDetailPageComponent {}
