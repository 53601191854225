export enum NavigationPosition {
  LEFT = "left",
  RIGHT = "right",
}

export const navigationItems = [
  {
    label: "Startseite",
    url: "https://talentsurf.de",
  },
  {
    label: "Stellen",
    routerLink: "/jobs",
    desktop: {
      position: NavigationPosition.LEFT,
    },
  },
  {
    label: "Unternehmen",
    routerLink: "/companies",
    desktop: {
      position: NavigationPosition.LEFT,
    },
  },
  {
    label: "Migration",
    url: "https://talentsurf.de/migration-integration",
    desktop: {
      position: NavigationPosition.LEFT,
    },
  },
  {
    label: "Blog",
    url: "https://talentsurf.de/posts",
    desktop: {
      position: NavigationPosition.LEFT,
    },
  },
  {
    label: "Für Arbeitgeber",
    url: "https://talentsurf.de/arbeitgeber",
    desktop: {
      position: NavigationPosition.RIGHT,
    },
  },
  {
    label: "Ämter & Schulen",
    url: "https://talentsurf.de/%C3%A4mter-schulen",
    desktop: {
      position: NavigationPosition.RIGHT,
    },
  },
  {
    label: "Über uns",
    url: "https://talentsurf.de/%C3%BCber-uns",
    desktop: {
      position: NavigationPosition.RIGHT,
    },
  },
];
