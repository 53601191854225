import { Injectable } from "@angular/core";
import de from "../i18n/de";

function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

@Injectable()
export class TranslationService extends GenericClass<typeof de>() {
  de = de;
  constructor() {
    super();
    Object.assign(this, this.transformObjectToPath("", de));
  }

  concatIfExistsPath(path: string, suffix: string): string {
    return path ? `${path}.${suffix}` : suffix;
  }

  transformObjectToPath<T extends object | string>(
    suffix: string,
    objectToTransformOrEndOfPath: T,
    path = "",
  ): T {
    return typeof objectToTransformOrEndOfPath === "object"
      ? Object.entries(objectToTransformOrEndOfPath).reduce(
          (objectToTransform, [key, value]) => {
            (objectToTransform as any)[key] = this.transformObjectToPath(
              key,
              value,
              this.concatIfExistsPath(path, suffix),
            );

            return objectToTransform;
          },
          {} as T,
        )
      : (this.concatIfExistsPath(path, suffix) as T);
  }
}
