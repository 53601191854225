import { bootstrapApplication } from "@angular/platform-browser";
import jss from "jss";
import preset from "jss-preset-default";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";

jss.setup(preset());

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
