import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TranslationService } from "../services/translation.service";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "talentsurf-app-job-overview-page",
  template: ` <h1>Hello JobOverviewPageComponent!</h1> `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
  providers: [TranslationService],
})
export class JobOverviewPageComponent {
  translations = inject(TranslationService);
}
