import { Route } from "@angular/router";
import { JobOverviewPageComponent } from "./pages/job-overview-page.component";
import { CompanyOverviewPageComponent } from "./pages/company-overview-page.component";
import { CompanyDetailPageComponent } from "./pages/company-detail-page.component";

export const appRoutes: Route[] = [
  { path: "jobs", component: JobOverviewPageComponent },
  {
    path: "companies",
    component: CompanyOverviewPageComponent,
  },
  {
    path: "companies/:id",
    component: CompanyDetailPageComponent,
  },
];
