import { ChangeDetectionStrategy, Component } from "@angular/core";
import { navigationItems } from "../../models/navigation.model";
import { IonicModule } from "@ionic/angular";

@Component({
  selector: "talentsurf-app-mobile-navigation",
  template: `
    <!-- <div
      class="w-full h-screen absolute top-0 left-0 -z-1 p-4 pt-20 flex flex-col lg:hidden bg-white dark:bg-gray-800 transition duration-500 text-slate-800 -translate-y-full opacity-0"
      x-bind:class="menuOpen ? '' : '-translate-y-full opacity-0'"
    >
      <ul class="w-full h-full flex flex-col items-center overflow-y-auto">
        @for (item of navigationItems; track $index) {
          <a [href]="item.url">
            <button
              class="inline-flex items-center p-3 text-sm tracking-widest cursor-pointer transition nav-link m-4 transition-all duration-300 delay-550 opacity-0 -translate-x-8"
              x-bind:class="menuOpen ? '' : 'opacity-0 -translate-x-8'"
            >
              {{ item.label }}
            </button>
          </a>

          @if ($index < navigationItems.length - 1) {
            <div class="w-4/5 h-px bg-gray-200 dark:bg-gray-600"></div>
          }
        }
      </ul>
    </div> -->
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule],
  providers: [],
})
export class MobileNavigationComponent {
  navigationItems = navigationItems;
}
