import { ChangeDetectionStrategy, Component } from "@angular/core";
// eslint-disable-next-line @nx/enforce-module-boundaries
import pkg from "../../../../../package.json";

@Component({
  selector: "talentsurf-app-footer",
  template: `
    <footer
      class="bg-primary-600 w-full bg-[#E2E6E7] p-4 md:py-10 text-slate-100"
    >
      <div class="w-full centered mx-auto">
        <div class="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 mb-4">
          <div>
            <p class="w-full font-bold mb-4">Hauptseiten</p>
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/%C3%BCber-uns"
              >Über uns</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/job-ads"
              >Alle Stellen</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/companies"
              >Alle Unternehmen</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/posts"
              >Blog</a
            >
          </div>
          <div>
            <p class="w-full font-bold mb-4">Zielgruppen</p>
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/migration-integration"
              >für Schüler und Migranten</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/arbeitgeber"
              >für Arbeitgeber</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/%C3%A4mter-schulen"
              >für Ämter &amp; Schulen</a
            >
          </div>
          <div>
            <p class="w-full font-bold mb-4">Weitere</p>

            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/privacy"
              >Datenschutz</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/imprint"
              >Impressum</a
            >
            <a
              class="w-full mb-1.5 hover:underline block"
              href="https://talentsurf.de/login"
            >
              <div class="flex space-x-1 items-center">
                <div class="w-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <span>Unternehmenslogin</span>
              </div>
            </a>
          </div>
          <div>
            <p class="w-full font-bold mb-4">Kontakt</p>

            <a
              class="w-full mb-1.5 hover:underline block text-sm"
              href="mailto:contact@talentsurf.de"
              >info&#64;talentsurf.de</a
            >
            <a
              class="transition text-slate-300 hover:text-[#0e76a8]"
              href="https://www.linkedin.com/company/talentsurfkarriere"
              target="_blank"
              title="Talentsurf on LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="currentColor"
              >
                <title>Talentsurf on LinkedIn</title>
                <path
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="w-full flex justify-center mb-4 lg:mb-6 opacity-80">
          <div class="dark:hidden">
            <img
              class="h-8 w-auto"
              src="https://talentsurf.de/img/corporate-design/xlogo-mobile-white.png.pagespeed.ic.YMy1cN_f7V.webp"
              alt="Talentsurf"
            />
          </div>
        </div>
        <p
          class="w-full tracking-wide text-center text-slate-300 opacity-70 text-xs mb-1.5"
        >
          <a target="_blank" class="hover:underline"
            >Made with ♡ by Pete & Max</a
          >
        </p>
        <p
          class="w-full tracking-wider text-center text-slate-300 opacity-80 text-xs "
        >
          ©2024 Talentsurf - {{ version }}
        </p>
      </div>
    </footer>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  providers: [],
})
export class FooterComponent {
  version = pkg?.version || "-";
}
