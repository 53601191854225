import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TranslationService } from "../services/translation.service";
import { TranslateModule } from "@ngx-translate/core";
import { CompanyOverviewHeaderComponent } from "../components/desktop/company-overview-header.component";
import { CompanyCardComponent } from "../components/desktop/company-card.component";

@Component({
  selector: "talentsurf-app-company-overview-page",
  template: `
    <talentsurf-app-company-overview-header />

    <div class="scaffold">
      <section class="mb-12">
        <div
          class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6"
        >
          @for (company of companies; track $index) {
            <talentsurf-app-company-card [company]="company" />
          }
        </div>
      </section>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    CompanyOverviewHeaderComponent,
    CompanyCardComponent,
  ],
  providers: [TranslationService],
})
export class CompanyOverviewPageComponent {
  translations = inject(TranslationService);
  companies = [
    {
      id: "9c1e63b1-51d7-48fd-956a-3768f4a5447b",
      headerImageUrl:
        "https://talentsurf.de/files/9c1e62e6-c6e1-4a28-a6eb-4888df099db1/get/lg",
      logo: "https://talentsurf.de/files/9c5f1ee5-816d-4118-abd1-e16719919dec/get",
      name: "Vogel-Bau Gruppe",
      metaData: [
        {
          iconUrl: "/logo_building.svg",
          text: "Bauhauptgewerbe",
        },
        {
          iconUrl: "/logo_location.svg",
          text: "Lahr/Schwarzwald",
        },
        {
          iconUrl: "/logo_education.svg",
          text: "Ausbildung",
        },
        {
          iconUrl: "/logo_jobs.svg",
          text: "1 Stellenanzeigen",
        },
      ],
    },
    {
      id: "9c26e016-7b03-4e9b-952e-a14df705b04b",
      headerImageUrl:
        "https://talentsurf.de/files/9c26d981-ec04-481b-8d91-5075b9de59f1/get/lg",
      logo: "https://talentsurf.de/files/9c26d95d-fe97-4502-92c7-46282e9cfad1/get",
      name: "Kohler Lebensmittel GmbH",
      metaData: [
        {
          iconUrl: "/logo_building.svg",
          text: "Lebensmittelhandel",
        },
        {
          iconUrl: "/logo_location.svg",
          text: "Lahr/Schwarzwald",
        },
        {
          iconUrl: "/logo_education.svg",
          text: "Ausbildung",
        },
        {
          iconUrl: "/logo_jobs.svg",
          text: "1 Stellenanzeigen",
        },
      ],
    },
  ];
}
