import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "talentsurf-app-company-overview-header",
  template: `
    <div class="relative isolate">
      <div class="overflow-hidden">
        <div
          class="mx-auto max-w-8xl px-6 pb-6 lg:pb-10 pt-10 sm:pt-12 lg:px-8 lg:pt-20"
        >
          <div
            class="mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center"
          >
            <div class="relative mx-auto max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1
                class="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl"
              >
                Unternehmen bei Talentsurf
              </h1>
              <p
                class="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none"
              >
                Entdecke passende Unternehmen und ihre Stellenanzeigen.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      >
        <div
          class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-primary-700 to-primary-400 opacity-30"
          style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)"
        ></div>
      </div>
      <img
        src="https://talentsurf.de/img/forms/xForm-6-Eck-Tuerkies.png.pagespeed.ic.2-zYVu0neG.webp"
        alt="Form"
        class="w-[180px] md:w-[250px] absolute left-0 -bottom-16 sm:bottom-0 -z-10 opacity-60 sm:opacity-100"
      />
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
  providers: [],
})
export class CompanyOverviewHeaderComponent {}
