import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
  navigationItems,
  NavigationPosition,
} from "../../models/navigation.model";
import { CommonModule } from "@angular/common";
import { RouterOutlet, RouterLink, RouterLinkActive } from "@angular/router";

@Component({
  selector: "talentsurf-app-desktop-navigation",
  template: `
    <div
      style="height: 78px;"
      class="bg-primary-600 py-2 w-full h-full relative text-slate-100"
    >
      <div class="centered flex py-2 items-center justify-between">
        <div class="lg:flex-1 hidden lg:flex space-x-4">
          <nav class="justify-center hidden lg:flex">
            @for (item of navigationItemsLeft; track $index) {
              @if (item.routerLink) {
                <a
                  [routerLink]="item.routerLink"
                  [routerLinkActive]="activeClasses"
                >
                  <button
                    class="inline-flex items-center p-3 text-sm tracking-widest cursor-pointer transition nav-link"
                  >
                    {{ item.label }}
                  </button>
                </a>
              } @else {
                <a [href]="item.url">
                  <button
                    class="inline-flex items-center p-3 text-sm tracking-widest cursor-pointer transition nav-link"
                  >
                    {{ item.label }}
                  </button>
                </a>
              }
            }
          </nav>
        </div>
        <div class="mr-4">
          <a href="https://talentsurf.de">
            <div class="p-2">
              <img
                class="h-8 w-auto lg:mx-auto"
                src="https://talentsurf.de/img/corporate-design/xlogo-desktop-white.png.pagespeed.ic.Fn2Ai3J23b.webp"
                alt="Talentsurf"
              />
            </div>
          </a>
        </div>
        <div class="flex-1 justify-end items-center flex space-x-4">
          <nav class="justify-center items-center hidden lg:flex">
            @for (item of navigationItemsRight; track $index) {
              <a [href]="item.url">
                <button
                  class="inline-flex items-center p-3 text-sm tracking-widest cursor-pointer transition nav-link"
                  alt="Stellen| Talentsurf"
                >
                  {{ item.label }}
                </button>
              </a>
            }
          </nav>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  providers: [],
})
export class DesktopNavigationComponent {
  navigationItems = navigationItems;
  get navigationItemsLeft() {
    return this.navigationItems.filter(
      (item) => item.desktop?.position === NavigationPosition.LEFT,
    );
  }
  get navigationItemsRight() {
    return this.navigationItems.filter(
      (item) => item.desktop?.position === NavigationPosition.RIGHT,
    );
  }
  readonly activeClasses = "rounded-lg border border-dashed border-white/40";
}
